import React, { useState } from 'react';
import queryString from 'query-string';
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from '../../hooks/useForm';
import buscadorStyles from './Buscador.module.css';
import BuscadorIcon from '../../assets/img/buscador-icon.png';
import ImgNotiexpress from "../../assets/img/logonexpress.png"

export const Buscador = () => {

    const [ indPautaBuscada, setIndPautaBuscada ] = useState(false)

    const { search:PautaBuscada } = useLocation();

    const [ formValues, handleInputChange ] = useForm({
        name: ''
    })

    const { name } = formValues;

    const { pauta = '' } = queryString.parse( PautaBuscada )

    const history = useHistory()

    const handleSubmit = (e) => {
        e.preventDefault();
        setIndPautaBuscada(true)
 
        history.push(`/pauta?buscar=${ name }`);
    }

    return (
        <div className={buscadorStyles.containerBusc}>
            <div className={ buscadorStyles.bloqueImagen }>
                <img className={buscadorStyles.imgNotiexpress} src={ ImgNotiexpress } />
            </div>
            <form onSubmit={handleSubmit} className={ buscadorStyles.formulario }>
                <input type="text"
                    name="name"
                    value={name}
                    placeholder="Buscar..."
                    className={buscadorStyles.searchForm}
                    onChange={handleInputChange}
                    required
                />
                <button type="submit"
                    className={buscadorStyles.searchBtn} >
                    <img src={BuscadorIcon}
                        style={
                            { width: "20px" }}
                    />
                </button>
            </form>
        </div>
    );
}
