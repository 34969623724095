
import React from 'react'
import { Redirect, Route } from 'react-router-dom'

import PropTypes from 'prop-types'

export const PrivateRouter = ({
    isAuthenticated,
    component: Component,
    ...rest
}) => {
    const path      = rest.location.pathname
    const search    = rest.location.search
    
    localStorage.setItem('ultimoPagVisitada', path + search);

    return (
        <Route { ...rest } 
            component={ (props) => (
                ( isAuthenticated ) 
                    ? <Component { ...props } />
                    : <Redirect to="/login" />
            )}
        />
    )
}

PrivateRouter.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    component: PropTypes.func.isRequired
}