import React, { useContext } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import IconUser from '../../assets/img/user-icon.png';
import IconLogout from '../../assets/img/logout.png';
import { AuthContext } from '../../auth/AuthContext';
import { types } from '../../types/types';

import LogoIcon from "../../assets/img/logo-icon.png"

import './Navbar.css';
import './Hamburguer.css';

export const Navbar = () => {

    const { user:{name}, dispatch } = useContext(AuthContext);
    const history = useHistory();

    const handleLogout = () => {
        history.replace('/login');
        dispatch({
            type: types.logout,
            logged: false
        })
    }

    return (
            <div className="containerBarMain">
                <div className="headerLeft">
                    <nav role="navigation">
                        <div id="menuToggle">
                            <input type="checkbox" />
                            
                            <span></span>
                            <span></span>
                            <span></span>
                            
                            <ul id="menu">
                            <div>
                                <img className="imgResponsiveMenu" src={LogoIcon} />
                            </div>

                            <hr className="separadorMenuResponsive" />
                            
                            <NavLink exact to="/buscar">
                                <p className="items">BUSCADOR</p>
                            </NavLink>
                            <NavLink exact to="/login">
                                <p className="items">CERRAR SESIÓN</p>
                            </NavLink>
                            </ul>
                        </div>
                    </nav>
                    <NavLink exact to="/buscar">
                        <img className="imgCabeceraPrincipal" src={LogoIcon} />
                    </NavLink>
                    <NavLink 
                        className="barraHeaderMain"
                        activeClassName="active"
                        exact
                        to="/buscar"
                    >
                        <p className="items">BUSCADOR</p>
                    </NavLink>
                </div>
                <div className="headerRight">
                    <img className="imgUsr" src={ IconUser }/>
                    <span className="seccionNomUsr">{ name }</span>
                    <img
                        className="seccionLogout"
                        onClick={handleLogout}
                        title="Salir"
                        src={IconLogout} 
                    />
                </div>
            </div>
    )
}
