import React from 'react'
import './Spinner.css';

export const Spinner = ({ size }) => {
    
    const loadingContainer = {
        position: 'relative'
    };

    return (
        <div>
            <div id="icon-background">
				<div>
					<div style={loadingContainer}>
                        <div 
                            data-loader="logo-circle" 
                            style={{'height': + size + 'px', 'width': + size + 'px'}}
                        >
                        </div>
					</div>
				</div>
			</div>
        </div>
    )
}
