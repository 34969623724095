import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Buscador } from '../components/Buscador/Buscador'
import { PautaLista } from '../components/pauta/PautaLista'
import { quiosco } from '../components/quiosco/quiosco'
import { MisTemas } from '../components/temas/MisTemas'
import { Navbar } from '../components/ui/Navbar'

export const DashboardRouter = () => {
    return (
        <>
            <Navbar />
            <div>
                <Switch>
                    <Route exact path="/buscar" component={Buscador} />
                    <Route exact path="/pauta" component={PautaLista} />
                    <Route exact path="/mis-temas" component={MisTemas} />
                    <Route exact path="/quiosco" component={quiosco} />

                    <Redirect to="/buscar" />
                </Switch>
            </div>
        </>
    )
}