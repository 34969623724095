import React, { useContext } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import { AuthContext } from '../auth/AuthContext';
import Login from '../views/Login/Login';
import RecuperarContrasena from '../views/Login/RecuperarContrasena';
import RestablecerContrasena from '../views/Login/RestablecerContrasena';
import { DashboardRouter } from './DashboardRouter';
import { PautaDetalleRouter } from './PautaDetalleRouter';
import { PrivateRouter } from './PrivateRouter';

export const AppRouter = () => {

    const {user} = useContext(AuthContext)
    
    return (
        <Router>
            <Switch>
                <Route exact path="/login" component={ Login } />
                <Route exact path="/recuperar-contrasena" component={ RecuperarContrasena } />
                <Route exact path="/restablecer-contrasena" component={ RestablecerContrasena } />
                
                <Route 
                    path="/detalle" 
                    component={ PautaDetalleRouter } 
                    isAuthenticated={ user.logged }
                />
                
                <PrivateRouter 
                    path="/" 
                    component={ DashboardRouter } 
                    isAuthenticated={ user.logged }
                />
            </Switch>
        </Router>
    )
}