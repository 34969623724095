import React, { useState } from 'react'
import PautaDetalleEstilo from './PautaDetalle.module.css';

import IconoVisorGral from "../../assets/img/Iconos-27.png"
import IconoVisorTxt from "../../assets/img/Iconos-29.png"
import IconoVisorGralSelected from "../../assets/img/Iconos-35.png"
import IconoVisorTxtSelected from "../../assets/img/Iconos-37.png"

export const OpcionesInternet = () => {
    const [
        {
            btnPredet, btnTxt
        }, 
        setBtnResaltado ] = useState ( {
            btnPredet: true,
            btnTxt: false
        }
    )

    const cambiarTipoVisorPrensa = (tipo) =>  {
        setBtnResaltado(
            {
                btnPredet: false,
                btnTxt: false
            }
        )

        switch (tipo) {
            case 1:
                    setBtnResaltado({ btnPredet: true })
                break;
            case 2:
                    setBtnResaltado({ btnTxt: true })
                break;
        }
    }

    return [ 
        <div className={ PautaDetalleEstilo.opcionesInternet }>
            { 
                btnPredet 
                ? <img style={{ width: "40px" }} onClick={ () => cambiarTipoVisorPrensa(1) } src={ IconoVisorGralSelected } />  
                : <img onClick={ () => cambiarTipoVisorPrensa(1) } src={ IconoVisorGral } /> 
            } 
            { 
                btnTxt 
                ? <img style={{ width: "40px" }} onClick={ () => cambiarTipoVisorPrensa(2) } src={ IconoVisorTxtSelected } /> 
                : <img onClick={ () => cambiarTipoVisorPrensa(2) } src={ IconoVisorTxt } /> 
            }
        </div>,
       btnPredet, btnTxt
    ]
}
