import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import LoginEstilos from './Login.module.css';
import ImgNotiexpress from "../../assets/img/logonexpress.png"
import ImgMostrarPass from "../../assets/img/mostrar-pass.png"
import ImgOcultarPass from "../../assets/img/ocultar-pass.png"
import ApiService from '../../services/ApiService'
import { Redirect, useHistory } from 'react-router-dom'
import { Spinner } from '../../components/Spinners/Spinner';
import { AuthContext } from '../../auth/AuthContext';
import { types } from '../../types/types';
import { useAlert } from 'react-alert';

function Login() {
    const { register, errors, handleSubmit } = useForm();
    const history                            = useHistory();
    const { dispatch }                       = useContext( AuthContext );
    const alert                              = useAlert()

    const [isLoading, setIsLoading]     = useState(false);
    const [mostrarPass, setMostrarPass] = useState(false)
    const [password, setPassword]       = useState('')

    const invertirMostrarPass = () => setMostrarPass(!mostrarPass);

    const cambiar = ( { currentTarget } ) => setPassword(currentTarget.value)

    const irRecuperarPass = (e) => {
        history.push('/recuperar-contrasena');
    }

    const onSubmit = (data) => {
        setIsLoading(true);

         ApiService.iniciarSesion(data).then(response => {
             if (response.data) {
                 const ultimaPaginaVisitada = localStorage.getItem('ultimoPagVisitada') || '/';
        
                 dispatch({
                     type: types.login,
                     payload: {
                         name: response.data.user.name
                     }
                 })
        
                 localStorage.setItem("AuthenticationToken", response.data.token);
                 setIsLoading(false)
                 history.replace(ultimaPaginaVisitada)
             }
         }, error => {
             setIsLoading(false)
             alert.error();
         });
    }

    return (
        <div className={LoginEstilos.containerLogin}>
            <form onSubmit= {handleSubmit(onSubmit)} className={ LoginEstilos.formularioLogin }>
                <img className={LoginEstilos.imgNotiexpress} src={ ImgNotiexpress } />
            {/* <form onSubmit= {handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}> */}
                <input
                    type="text"
                    name="email"
                    className={LoginEstilos.entradaCredencial}
                    ref={
                        register({
                            required:true
                    })}
                    placeholder="Usuario"
                />
                {errors.desEmail && <p className={LoginEstilos.errorMensaje}>El usuario es requerido</p>}
                <div className={LoginEstilos.campo}>
                    <input
                        type={ mostrarPass ? 'text' : 'password' }
                        name="password"
                        value={ password }
                        onChange={ cambiar }
                        className={LoginEstilos.entradaCredencial}
                        ref={register({
                            required:true
                        })}
                        placeholder="Contraseña"
                    />
                    <span onClick={invertirMostrarPass}>
                        { mostrarPass ? <img src={ ImgOcultarPass } /> : <img src={ ImgMostrarPass } /> }
                    </span>
                </div>
                {errors.desContrasenia && <p className={LoginEstilos.errorMensaje}>La contraseña es requerida</p>}
                {
                    isLoading
                    ? <Spinner size="30"/>
                    : <button type="submit" className={LoginEstilos.btnIngresar}>INGRESAR</button>
                }

                <div className={LoginEstilos.recuperarPass}>
                    <span onClick={irRecuperarPass}>¿Has olvidado la contraseña?</span>
                </div>
            </form>
        </div>
    )
}

export default Login;
