import React, { useEffect, useState } from 'react'
import ApiService from '../../services/ApiService'
import { Spinner } from '../Spinners/Spinner'
import { PautaTarjeta } from './PautaTarjeta';
import PautaEstilos from './Pauta.module.css';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useForm } from '../../hooks/useForm';
import LogoIcon from "../../assets/img/logo-icon.png"
import SelectIcon from "../../assets/img/Iconos-18.png"
import BuscadorIcon from '../../assets/img/buscador-icon.png';
import buscadorStyles from '../Buscador/Buscador.module.css';
import styled from "styled-components";
import Select, { components } from 'react-select';

import moment from 'moment';

const options = [
    { value: 1, label: 'HOY' },
    { value: 2, label: '48 HORAS' },
    // { value: 3, label: '1 SEMANA' },
    // { value: 4, label: '1 MES' },
];

const opcionesOrden = [
    { value: 1, label: 'FECHA DESCENDENTE' },
    { value: 2, label: 'FECHA ASCENDENTE' }
];

const aMedios = [
    { value: 'TODOS', label: 'TODOS' },
    { value: 'PRENSA', label: 'PRENSA' },
    { value: 'TELEVISIÓN', label: 'TELEVISIÓN' },
    { value: 'RADIO', label: 'RADIO' },
    { value: 'INTERNET', label: 'INTERNET' },
];

const customStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isFocused && '#79207e',
          color: isFocused ? 'white' : 'gray'
        };
    },

    indicatorSeparator: base => ({
        ...base,
        display: 'none'
    }),

    control: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            border: '1px solid #cacacc',
            borderRadius: '15px',
            ':hover': {
                ...styles[':active'],
                color: !isDisabled && (isSelected ? 'green' : 'green'),
            },
        };
    },

    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
}

const DropdownIndicator = props => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
            <img style={{ width: '15px' }} src={ SelectIcon } />
        </components.DropdownIndicator>
      )
    );
};

export const PautaLista = ({ }) => {

    const path  = useLocation();
    const pauta = queryString.parse(path.search)

    const [ formValues, handleInputChange ] = useForm({
        name: pauta.buscar
    })

    const types = ["TODOS", "PRENSA", "TELEVISIÓN", "RADIO", "INTERNET"];

    const { name } = formValues;

    const [isLoading, setIsLoading]             = useState(true)
    const [pautas, setPautas]                   = useState([])
    const [active, setActive]                   = useState('TODOS');
    const [data, setData]                       = useState(true);
    const [dataFiltrada, setDataFiltrada]       = useState([]);
    const [periodo, setPeriodo]                 = useState(1);

    const [numPautasEncontradas, setNumPautasEncontradas] = useState('')
    const [indBusquedaPorFiltro, setIndBusquedaPorFiltro] = useState(false)
    
    const handleChangeSelect = ( { value:periodoSeleccionadoCombo, pautasEffect:response } ) => {
        let fecSetFiltro;
        let pautasObtenidas = (response !== undefined) ? response : pautas;

        setPeriodo(periodoSeleccionadoCombo)

        switch (periodoSeleccionadoCombo) {
            case 1:
                fecSetFiltro = moment();
                break;
            case 2:
                fecSetFiltro = moment().subtract(2, 'days');
                break;
            case 3:
                fecSetFiltro = moment().subtract(1, 'week');

                break;
            // case 4:
            //     fecSetFiltro = moment().subtract(1, 'months')
            //     break;
        }

        let pautasFiltroPorFecha = pautasObtenidas.filter(function(pauta){
            switch (active) {
                case 'TODOS':
                    return (pauta.fec_pauta >= fecSetFiltro.format('YYYY-MM-DD'));
                case 'PRENSA':
                    return (pauta.fec_pauta >= fecSetFiltro.format('YYYY-MM-DD') && pauta.id_tipo_medio === 1);
                case 'TELEVISIÓN':
                    return (pauta.fec_pauta >= fecSetFiltro.format('YYYY-MM-DD') && pauta.id_tipo_medio === 2);
                case 'RADIO':
                    return (pauta.fec_pauta >= fecSetFiltro.format('YYYY-MM-DD') && pauta.id_tipo_medio === 3);
                case 'INTERNET':
                    return (pauta.fec_pauta >= fecSetFiltro.format('YYYY-MM-DD') && pauta.id_tipo_medio === 4);
            }
        })

        setNumPautasEncontradas(pautasFiltroPorFecha.length + " RESULTADOS");
        setIndBusquedaPorFiltro(true);
        setDataFiltrada(pautasFiltroPorFecha);
    }

    const handleChangeSelectOrden = ( { value: orden } ) => { 
        const Moment = require('moment')

        let fecSetFiltro = [];
        let pautasOrdAsc = [];
        
        setData(!data);
        setPautas([]);

        fecSetFiltro = pautas;
        
        switch (orden) {
            case 1: //Descendente
                setIndBusquedaPorFiltro(!true);
                pautasOrdAsc  = pautas.sort((a,b) => new Moment(a.fec_pauta).format('YYYYMMDD') - new Moment(b.fec_pauta).format('YYYYMMDD'))
                fecSetFiltro = pautasOrdAsc.reverse((a,b) => new Moment(a.fec_pauta).format('YYYYMMDD') - new Moment(b.fec_pauta).format('YYYYMMDD'))
                break;
            case 2: //Ordenando ascendentemente 
                fecSetFiltro  = pautas.sort((a,b) => new Moment(a.fec_pauta).format('YYYYMMDD') - new Moment(b.fec_pauta).format('YYYYMMDD'))
                setIndBusquedaPorFiltro(!true);
            break;
        }
        setPautas(fecSetFiltro)
    }

    useEffect(() => {
        if (name == '') {
            return
        }
        ApiService.buscarPautas(name).then(
            response => {
                setPautas(response);
                handleChangeSelect({value: 1, pautasEffect: response});
                setIsLoading(false);
            }, error => { console.log('Error al buscar pautas') }
        )
    }, [])

    const history = useHistory()

    const handleSubmit = (e) => {
        setIsLoading(true);
        e.preventDefault();

        history.push(`/pauta?buscar=${ name }`);

        ApiService.buscarPautas(name).then(
            response => {
                setPautas([]) //limpiamos las pautas de la primera respuesta
                setPautas(response);
                setDataFiltrada([])
                setActive('HOY')

                handleChangeSelect({value: 1, pautasEffect: response});

                setIsLoading(false);
                filtrarPautasPorTipoNoticia(active)
            }, error => { console.log('Error al buscar pautas ') }
        )
    }

    function filtrarPautasPorTipoNoticia(type) {
        let nomTipoMedio;
        let fecSetFiltro;

        if (typeof type.value === 'undefined') {
            nomTipoMedio = type;
            setActive(type)
        } else {
            nomTipoMedio = type.value;
            setActive(nomTipoMedio)
        }

        switch (periodo) {
            case 1:
                fecSetFiltro = moment();
                break;
            case 2:
                fecSetFiltro = moment().subtract(2, 'days');
                break;
            case 3:
                fecSetFiltro = moment().subtract(1, 'week');
                break;
            // case 4:
            //     fecSetFiltro = moment().subtract(1, 'months')
            //     break;
        }

        let pautasFiltradas = pautas.filter(function (data) {
            // setPautas([])
            setDataFiltrada([])

            if (nomTipoMedio === 'TODOS') {
                return (data.fec_pauta >= fecSetFiltro.format('YYYY-MM-DD'));
            }
            if (nomTipoMedio === 'PRENSA') {
                return (data.fec_pauta >= fecSetFiltro.format('YYYY-MM-DD') && data.id_tipo_medio == 1);
            }
            if (nomTipoMedio === 'TELEVISIÓN') {
                return (data.fec_pauta >= fecSetFiltro.format('YYYY-MM-DD') && data.id_tipo_medio == 2);
            }
            if (nomTipoMedio === 'RADIO') {
                return (data.fec_pauta >= fecSetFiltro.format('YYYY-MM-DD') && data.id_tipo_medio == 3);
            }
            if (nomTipoMedio === 'INTERNET') {
                return (data.fec_pauta >= fecSetFiltro.format('YYYY-MM-DD') && data.id_tipo_medio == 4);
            }
        });
        setIndBusquedaPorFiltro(2);

        setDataFiltrada(pautasFiltradas)
        setNumPautasEncontradas(pautasFiltradas.length + " RESULTADOS")
    }

    const Pestania = styled.button`
        padding: 10px 30px;
        cursor: pointer;
        opacity: 0.6;
        background: #f9f9f9;
        border: 0;
        outline: 0;
        ${({ active }) =>
            active &&
            `font-weight: bold;
            padding: 20px 30px;
            color: #79207e;
            border-bottom: 6px solid #79207e;
            opacity: 1;`
        }`;

    return (
        <div className={PautaEstilos.containerPautaLista} id="bloquePautas">
            <div className={ PautaEstilos.headerPautaLista }>
                <div>
                    <form onSubmit={handleSubmit} >
                        <div className={ PautaEstilos.grupoEntradaBusqueda }>
                        {/* <img style={{ width: '60px' }} src={LogoIcon} /> */}
                        <img className={PautaEstilos.logoBuscadorListaPauta} src={LogoIcon} />
                            <input 
                                type="text"
                                name="name"
                                value={name}
                                placeholder="Buscar..."
                                className={buscadorStyles.searchForm}
                                onChange={handleInputChange}
                                required
                            />
                            <button type="submit" className={ buscadorStyles.searchBtn }>
                                <img src={ BuscadorIcon } style={{ width: "20px" }} />
                            </button>
                        </div>
                    </form>
                    
                    <div className={PautaEstilos.seccionPestanias}>
                        {
                            types.map(type => (
                                <Pestania 
                                    key={ type }
                                    active={ active === type }
                                    onClick={ () => filtrarPautasPorTipoNoticia(type) } 
                                >
                                    { type }
                                </Pestania>
                            ))
                        }
                    </div>

                    <div className={ PautaEstilos.seccionFiltrosResponsive }>
                        <div className={ PautaEstilos.bloqueMediosResponsive }>
                            <Select
                                components= {{ DropdownIndicator }}
                                placeholder={ 'MEDIOS' }
                                options={ aMedios }
                                styles={ customStyles }
                                onChange={ filtrarPautasPorTipoNoticia }
                            />
                        </div>
                        <div className={ PautaEstilos.bloquePeriodoResponsive }>
                            <Select
                                components= {{ DropdownIndicator }}
                                defaultValue={{ label: "HOY", value: 1 }}
                                options={ options }
                                styles={ customStyles }
                                onChange={ handleChangeSelect }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={PautaEstilos.filtrosAgrupadorOpciones } >
                {
                    !isLoading && 
                    <>
                        <div className={ PautaEstilos.filtroNumPautasEncontradas }>
                            <p className={ PautaEstilos.cantidadPautas }> { numPautasEncontradas } </p>
                        </div>
                        <div className={ PautaEstilos.filtroPeriodo }>
                            <Select
                                components={{ DropdownIndicator }}
                                defaultValue={{ label: "HOY", value: 1 }}
                                options={ options }
                                styles={ customStyles }
                                onChange={handleChangeSelect}
                            />
                        </div>
                        <div className={ PautaEstilos.filtroPeriodo }>
                            <Select
                                components={{ DropdownIndicator }}
                                placeholder={ 'ORDENAR POR FECHA' }
                                options={ opcionesOrden }
                                styles={ customStyles }
                                onChange={ handleChangeSelectOrden }
                            />
                        </div>
                        <div className={ PautaEstilos.filtroEmpty }>
                        </div>
                    </>
                }
            </div>
            <div className={PautaEstilos.contenedorlistaDePautas} >
                {  isLoading ?
                    <div className={PautaEstilos.spinnerListaPautas}>
                        < Spinner size="40" />
                    </div>
                     :
                    <>
                        <div className={PautaEstilos.contentColumn} id="listaPautas">
                            {
                                (!indBusquedaPorFiltro && (data || !data)) ?
                                pautas.map((oPauta, i) => (
                                    <PautaTarjeta
                                        tipo={oPauta.id_tipo_medio}
                                        titular={oPauta.des_titular}
                                        fecha={oPauta.fec_pauta}
                                        id={oPauta.id_pauta}
                                        medio={oPauta.des_medio}
                                    />
                                )) :
                                 dataFiltrada.map((oPauta, i) => (
                                    <PautaTarjeta
                                        tipo={oPauta.id_tipo_medio}
                                        titular={oPauta.des_titular}
                                        fecha={moment(oPauta.fec_pauta).format('DD-MM-YYYY')}
                                        id={oPauta.id_pauta}
                                        medio={oPauta.des_medio}
                                    />
                                ))
                            }
                        </div>
                    </>
                }
            </div>
        </div>
    )
}
