import React from 'react'
import { Spinner } from '../../../components/Spinners/Spinner'
import VisoresEstilos from '../Visores.module.css'
import moment from 'moment';

export const VisorInternet = ({
    isLoading,
    desTitular,
    desResumen,
    fecPauta,
    nomMedio,
    impEquivalencia,
    des_ruta_web,
    alcance,
    desRutaImagen,
    desPalabraClave
}) => {
    return (
        <div className={ VisoresEstilos.containerNoticia }>
            <div className={ VisoresEstilos.boxUno }>
                {isLoading 
                    ? 
                    <Spinner /> 
                    :
                    <div className={ VisoresEstilos.boxPrincipal }>
                        <div className={ VisoresEstilos.titular }>
                            {desTitular}
                        </div>
                        <div className={ VisoresEstilos.audio }>
                            <img src={ process.env.PUBLIC_URL + "/media/internet/"  + desRutaImagen  } style={{ width: '100%' }} />
                        </div>
                        <div className={ VisoresEstilos.descripcion }>
                            {desResumen}
                        </div>
                    </div>
                }
            </div>
            <hr className={VisoresEstilos.lineaSeparacion}></hr>
            <div className={ VisoresEstilos.boxDos } >
                {
                    isLoading 
                    ? 
                    <Spinner size="30"/> 
                    :
                    <>
                        <ul className={ VisoresEstilos.propiedadesNoticia}>
                            <li>FECHA: <span className={VisoresEstilos.desPropipedadNot}> { fecPauta } </span></li> 
                            <li>MEDIO: <span className={VisoresEstilos.desPropipedadNot}> { nomMedio } </span></li>
                            <li>LINK: <span className={VisoresEstilos.desPropipedadNot}> { des_ruta_web } </span></li>
                            <li>ALCANCE: <span className={VisoresEstilos.desPropipedadNot}> { alcance } </span></li>
                            <li>PALABRAS CLAVE: <span className={VisoresEstilos.desPropipedadNot}> { desPalabraClave } </span></li>
                        </ul>
                        <div className={ VisoresEstilos.importe } >
                            Valor (US$): { impEquivalencia } 
                        </div>
                    </>
                }
            </div>
        </div>
    )
}
