import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { PautaDetalle } from '../views/PautaDetalle/PautaDetalle'
import { Navbar } from '../components/ui/Navbar'

export const PautaDetalleRouter = () => {
    return (
        <>
            <div>
                <Switch>
                    <Route path="/detalle/:pautaId/:idTipoMedio" component={ PautaDetalle } />

                    <Redirect to="/buscar"/>
                </Switch>
            </div>
        </>
    )
}
