import React, { useState } from 'react'
import IconRadio from '../../assets/img/radio-icon.png';
import IconTv from '../../assets/img/tv-icon.png';
import IconInternet from '../../assets/img/internet-icon.png';
import IconPrensa from '../../assets/img/prensa-icon.png';
import IconPlus from '../../assets/img/plus-icon.png';
import "./PautaTarjeta.css";

export const PautaTarjeta = ({
    tipo,
    titular,
    fecha,
    id,
    medio
}) => {

    const [estiloSombreado, setEstiloSombreado] = useState("");

    function sombrear(indSombrea) {

        setEstiloSombreado(indSombrea ? "newcard" : "");
    }

    const containerText = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flex: 4,
    }

    const content = {
        display: 'flex',
        flexDirection: 'row',
    }

    const metadataContent = {
        display: 'flex',
        flexDirection: 'row',
    }

    switch (tipo) {
        case 1:
            var icon = IconPrensa;
            var nombre = '/gui/view/VistaPautaPrensa';
        break;
        case 2:
            var icon = IconTv;
            var nombre = '/Servicios/gui/view/VistaPautaTv2';
        break;
        case 3:
            var icon = IconRadio;
            var nombre = '/Servicios/gui/view/VistaPautaRadio';
        break;
        case 4:
            var icon = IconInternet;
            var nombre = '/Servicios/gui/view/VistaPautaInternet';
        break;

        default: icon = IconInternet;
    }

    var irIntranetNoticia = () => {
        window.open('https://servicios.noticiasperu.pe/' + nombre + '.php?idPauta=' + id + '&bool=0','_blank');
    }

    const verDetalle = () => {
        window.open(`${ process.env.REACT_APP_URL_DOMAIN }/detalle/${ id }/${ tipo }`);
    }

    return (
        <div
            className={`container ${estiloSombreado}`}
            onMouseEnter={() => sombrear(true)}
            onMouseLeave={() => sombrear(false)}
        >
            <div className="containerImage">
                <img className="iconoTipoMedio" src={icon} />
            </div>
            <div style={containerText}>
                <div style={content}>
                    <p className="contentItem" onClick={ verDetalle }>{ titular }</p>
                </div>
                <div style={metadataContent}>
                    <div>
                        <p className="metadataItem"> { fecha } / { medio ? medio : 'elcomercio.pe'}</p>
                    </div>
                </div>
            </div>
            <div className="containerButton">
                <a target="_blank" >
                    <img src={IconPlus} width={24} />
                </a>
            </div>
        </div>
    )
}
