import React from 'react'
import VisoresEstilos from '../Visores.module.css'
import { Spinner } from '../../../components/Spinners/Spinner';
import moment from 'moment';
import ImageGallery from "react-image-gallery";
// import stylesheet if you're not already using CSS @import
import "react-image-gallery/styles/css/image-gallery.css";


export const Predeterminado = ({ 
    isLoading, fecPauta, nomMedio, nomSeccion, numAlto, numAncho, numPagina, impEquivalencia, nomRecortePrensa, 
    desResumen, indMostrarPredet, indMostrarSeccion, indMostrarTxt, indMostrarImg, aRecorte
} ) => {
    let fecConsulta = moment(fecPauta, 'DD/MM/YYYY').format('YYYY/MM/DD');
    
    return (
        <div className={ VisoresEstilos.containerNoticia }>
            <div className={ VisoresEstilos.boxUno }>
            { 
                !isLoading 
                ?
                <>
                    { (indMostrarPredet === true) &&

                            // <img 
                            // className={ VisoresEstilos.img } 
                            // src={ process.env.PUBLIC_URL + "/media/Recortes1/" + fecConsulta  + "/" + recorte.nom_recorte_prensa } 
                            // /> 
                            <ImageGallery items={ aRecorte } showPlayButton={false} showFullscreenButton={false} />
                        }
                    { (indMostrarTxt === true) && desResumen }

                    { (indMostrarImg === true) && 
                        <ImageGallery items={ aRecorte } showPlayButton={false} showFullscreenButton={false} />
                        // <img 
                        //     src={ process.env.PUBLIC_URL + "/media/Recortes1/" + fecConsulta + "/" + nomRecortePrensa }  
                        //     style={{ width: '100%' }} 
                        // />
                    }
                    { (indMostrarSeccion === true) &&
                        <img 
                            src={ process.env.PUBLIC_URL + "/media/Recortes1/" + fecConsulta  + "/" + nomRecortePrensa }  
                            style={{ width: '50%' }} 
                        />
                    }
                </> 
                : 
                <Spinner />
            }
            </div>
            <hr className={VisoresEstilos.lineaSeparacion}></hr>
            {
                !isLoading 
                &&
                <>
                    <div className={ VisoresEstilos.boxDos } >
                        <ul className={ VisoresEstilos.propiedadesNoticia}>
                            <li>FECHA: <span className={VisoresEstilos.desPropipedadNot}> { fecPauta } </span></li> 
                            <li>MEDIO: <span className={VisoresEstilos.desPropipedadNot}> { nomMedio } </span></li>
                            <li>SECCIÓN: <span className={VisoresEstilos.desPropipedadNot}> { nomSeccion } </span></li>
                            <li>ALTO/ANCHO: 
                                <span className={VisoresEstilos.desPropipedadNot}> { numAlto } / { numAncho } </span>
                            </li>
                            <li>PÁGINA: <span className={VisoresEstilos.desPropipedadNot}> { numPagina } </span></li>
                            <li>VISTA COMPLETA:</li>
                        </ul>
                        <img 
                            src={ aRecorte[0].original } 
                            style={{ width:"80%" }}
                        />
                        <div className={ VisoresEstilos.importe } >
                            Valor (US$): { impEquivalencia }
                        </div>
                    </div>
                </>
            }
        </div>
    )
}
