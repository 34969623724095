import React from 'react'
import { Spinner } from '../../../components/Spinners/Spinner'
import VisoresEstilos from '../Visores.module.css'
import moment from 'moment';

export const VisorTv = ({
    isLoading,
    desTitular,
    desResumen,
    fecPauta,
    nomMedio,
    impEquivalencia,
    nomProgramaAv,
    horPauta,
    pautaId
}) => {
    return (
        <div className={ VisoresEstilos.containerNoticia }>
            <div className={ VisoresEstilos.boxUno }>
                {
                isLoading
                    ?
                    <Spinner />
                    :
                    <div className={ VisoresEstilos.boxPrincipal }>
                            <div className={ VisoresEstilos.titular }>
                                {desTitular}
                            </div>
                            <div className={ VisoresEstilos.tv }>
                                <video className={ VisoresEstilos.tvDimension } controls muted>
                                    <source
                                        src= {process.env.PUBLIC_URL + "/media/tv/" + moment(fecPauta, 'DD/MM/YYYY').format('YYYY/MM/DD') + "/" + pautaId + ".mp4"}
                                        type="video/mp4"
                                    />
                                </video>
                            </div>
                            <div className={ VisoresEstilos.descripcion }>
                                {desResumen}
                            </div>
                    </div>
                }
            </div>
            <hr className={VisoresEstilos.lineaSeparacion}></hr>
                <div className={ VisoresEstilos.boxDos } >
                    {
                        isLoading
                        ?
                        <Spinner size="30"/>
                        :
                        <>
                            <ul className={ VisoresEstilos.propiedadesNoticia}>
                                <li>FECHA: <span className={VisoresEstilos.desPropipedadNot}> { fecPauta } </span></li>
                                <li>MEDIO: <span className={VisoresEstilos.desPropipedadNot}> { nomMedio } </span></li>
                                <li>PROGRAMA: <span className={VisoresEstilos.desPropipedadNot}> { nomProgramaAv } </span></li>
                                <li>HORA: <span className={VisoresEstilos.desPropipedadNot}> { horPauta } </span></li>
                            </ul>
                            <div className={ VisoresEstilos.importe } >
                                Valor (US$): { impEquivalencia }
                            </div>
                        </>
                    }
                </div>
        </div>
    )
}
