import React, { useEffect, useReducer } from 'react';
import './App.css';
import { AuthContext } from './auth/AuthContext';
import { authReducer } from './auth/authReducer';
import { AppRouter } from './routes/AppRouter';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from './components/Alerts/AlertTemplate';

const init = () => {
  return JSON.parse(localStorage.getItem('user')) || { logged:false }
}

const options = {
  position: positions.TOP_RIGHT,
  timeout: 4000,
  offset: '30px',
  transition: transitions.SCALE
}

function App() {

  const [ user, dispatch ] = useReducer(authReducer, {}, init)

  useEffect(() => {
    localStorage.setItem('user', JSON.stringify(user));
  }, [user])

  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <AuthContext.Provider value={{ user, dispatch }}>
        <AppRouter />
      </AuthContext.Provider>
    </AlertProvider>
  );
}

export default App;
