import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import LoginEstilos from './Login.module.css';
import ImgNotiexpress from "../../assets/img/logonexpress.png"
import ApiService from '../../services/ApiService'
import { useHistory } from 'react-router-dom'
import { Spinner } from '../../components/Spinners/Spinner';
import { useAlert } from 'react-alert';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

const delay = 5;

function RestablecerContrasena() {
    const { register, handleSubmit } = useForm();
    
    const [token, setToken]                         = useState('');
    const [mensaje, setMensaje]                     = useState('');
    const [indProcesoResPass, setIndProcesoResPass] = useState(false);
    const [isLoading, setIsLoading]                 = useState(false);
    
    const alert     = useAlert()
    const history   = useHistory();
    const url       = useLocation();

    const ruta = queryString.parse(url.search)

    useEffect(() => {
        setToken(ruta.token);
    }, [])

    const onSubmit = (data) => {
        setIsLoading(true);
         ApiService.restablecerContrasenia(data).then(response => {
             setIsLoading(false)
             setMensaje(response.message)
             if (response.indProceso) {
                setIndProcesoResPass(true);
                setTimeout(() => history.replace('/'), delay * 1000);
             } else {
                alert.error();
             }
         }, error => {
             setIsLoading(false)
             alert.error();
         });
    }

    return (!indProcesoResPass) ? (
        <div className={LoginEstilos.containerLogin}>
        <form onSubmit= {handleSubmit(onSubmit)} className={ LoginEstilos.formularioLogin }>
            <img className={LoginEstilos.imgNotiexpress} src={ ImgNotiexpress } />
            <div className={LoginEstilos.mensajePrincipalRecPass}>
                {(!mensaje) && <h3>Restablecer contraseña </h3>}
            </div>
            <input
                type="email"
                name="email"
                className={LoginEstilos.entradaCredencial}
                ref={
                    register({
                        required:true
                })}
                placeholder="Ingrese Email"
            />
            <input
                type="password"
                name="password"
                className={LoginEstilos.entradaCredencial}
                ref={
                    register({
                        required:true
                })}
                placeholder="Nueva Contraseña"
            />
            <input
                type="password"
                name="password_confirmation"
                className={LoginEstilos.entradaCredencial}
                ref={
                    register({
                        required:true
                })}
                placeholder="Repetir Contraseña"
            />
             <input
                type="hidden"
                name="token"
                value={ token }
                ref={
                    register({
                        required:true
                })}
            />
            {
                isLoading
                ? <Spinner size="30"/>
                : <button type="submit" className={LoginEstilos.btnIngresar}>RESTABLECER</button>
            }
        </form>
    </div>
    ) : (
        <div className={LoginEstilos.containerLogin}>
            <div className={ LoginEstilos.formularioLogin }>
                <img className={LoginEstilos.imgNotiexpress} src={ ImgNotiexpress } />
                <span className={LoginEstilos.msjResPassExito}>{mensaje}</span>
                <br></br>
                <Spinner size="30"/>
                <p className={LoginEstilos.msjRedireccionamiento}>Te llevaremos al Login...</p>
            </div>
        </div>
    )
}

export default RestablecerContrasena;