import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import LoginEstilos from './Login.module.css';
import ImgNotiexpress from "../../assets/img/logonexpress.png"
import ApiService from '../../services/ApiService'
import { Spinner } from '../../components/Spinners/Spinner';
import { useAlert } from 'react-alert';

function RecuperarContrasena() {
    const { register, handleSubmit } = useForm();

    const [isLoading, setIsLoading]     = useState(false);
    const [indProceso, setIndProceso]   = useState(false)
    const [mensaje, setMensaje]         = useState('')

    const alert = useAlert()

    const onSubmit = (data) => {
        setIsLoading(true);
         ApiService.recuperarContrasenia(data).then(response => {
            if (response.ind_proceso) {
                setIndProceso(true);
            } 

            setMensaje(response.status)
            setIsLoading(false);
         }, error => {
             setIsLoading(false)
             alert.error();
         });
    }

    return (!indProceso) ? (
        <div className={LoginEstilos.containerLogin}>
        <form onSubmit= {handleSubmit(onSubmit)} className={ LoginEstilos.formularioLogin }>
            <img className={LoginEstilos.imgNotiexpress} src={ ImgNotiexpress } />
            <div className={LoginEstilos.mensajePrincipalRecPass}>
                <h3>Ingresa tu email </h3>
            </div>
            <div className={LoginEstilos.recuperarPass}>
                <span>Te enviaremos un enlace a tu email para que puedas <br></br>crear una nueva contraseña.</span>
            </div>
            <input
                type="email"
                name="email"
                className={LoginEstilos.entradaCredencial}
                ref={
                    register({
                        required:true
                })}
                placeholder="Correo"
            />
            {
                isLoading
                ? <Spinner size="30"/>
                : <button type="submit" className={LoginEstilos.btnIngresar}>INGRESAR</button>
            }
        </form>
    </div>
    ) : (
        <div className={LoginEstilos.containerLogin}>
            <div className={ LoginEstilos.formularioLogin }>
                <img className={LoginEstilos.imgNotiexpress} src={ ImgNotiexpress } />
                <span className={LoginEstilos.msjEnvioEnlace}>{mensaje}</span>
                <br></br>
                <span className={LoginEstilos.msjCerrarVentana}>Puedes cerrar esta ventana.</span>
            </div>
        </div>
    )
}

export default RecuperarContrasena;