class ApiService {

    url = process.env.REACT_APP_URL;

    serverRequest(method, path, body) {

      const header = new Headers();

      header.append("Content-Type", "application/json");

      return fetch(`${this.url}${path}`, {
          method: method,
          body: body,
          headers: header
      }).then(
        response =>  {
          if(response.ok) return response;
          else throw new Error("Hubo un error en la petición");
        },
        error => {
          throw new Error("Hubo un problema en la conexión a la red");
        }
      )
      .finally(() => {

      });
    }

    buscarPautas(desResumen) {
        return this.serverRequest("GET", `/buscar-pautas-por-resumen/${desResumen}`).then(response => response.json() );
    }

    buscarPautaDetalle(idPauta, idTipoMedio) {
        return this.serverRequest("GET", `/pauta/${ idPauta }/${ idTipoMedio }`).then(response => response.json() );
    }

    iniciarSesion(desCredenciales) {
      // return this.serverRequest("POST", "/iniciar-sesion", JSON.stringify(desCredenciales)).then(response => response.json());
      return this.serverRequest("POST", "/login", JSON.stringify(desCredenciales)).then(response => response.json());
    }

    recuperarContrasenia(email) {
      return this.serverRequest("POST", "/forgot-password", JSON.stringify(email)).then(response => response.json());
    }

    restablecerContrasenia(data) {
      return this.serverRequest("POST", "/reset-password", JSON.stringify(data)).then(response => response.json());
    }
}
export default new ApiService();
