import React from 'react';
import Alert from './Alert';

function AlertTemplate({ style, options, message, close }) {

  return (

    <div style={style}>
      {options.type === 'info' && <Alert message={'Algo sucedió, por favor reporte esto al administrador'} type={options.type} close={close}/>}
      {options.type === 'success' && <Alert message={'Operación realizada con éxito'} type={options.type} close={close}/>}
      {options.type === 'error' && <Alert message={'Las credenciales ingresadas no son válidas.'} type={options.type} close={close}/>}
      {message}

    </div>

  )
}

export default AlertTemplate