import React, { useState } from 'react'
import PautaDetalleEstilo from './PautaDetalle.module.css';

import IconoVisorGral from "../../assets/img/Iconos-27.png"
import IconoVisorImg from "../../assets/img/Iconos-28.png"
import IconoVisorTxt from "../../assets/img/Iconos-29.png"
import IconoVisorImgT from "../../assets/img/Iconos-30.png"
import IconoVisorGralSelected from "../../assets/img/Iconos-35.png"
import IconoVisorSeccionSelected from "../../assets/img/Iconos-36.png"
import IconoVisorTxtSelected from "../../assets/img/Iconos-37.png"
import IconoVisorImgSelected from "../../assets/img/Iconos-38.png"

export const OpcionesPrensa = () => {

    const [
        {
            btnPredet, btnSeccion, 
            btnTxt, btnImg
        }, 
        setBtnResaltado ] = useState ( {
            btnPredet: true,
            btnSeccion: false,
            btnTxt: false,
            btnImg: false,
        }
    )

    const cambiarTipoVisorPrensa = ( tipo ) =>  {
        setBtnResaltado(
            {
                btnPredet: false,
                btnSeccion: false,
                btnTxt: false,
                btnImg: false,
            }
        )

        switch (tipo) {
            case 1:
                    setBtnResaltado({ btnPredet: true })
                break;
            case 2:
                    setBtnResaltado({ btnSeccion: true })
                break;
            case 3:
                    setBtnResaltado({ btnTxt: true })
                break;
            case 4:
                    setBtnResaltado({ btnImg: true })
                break;
        }
    }

    return [ 
        <div className={ PautaDetalleEstilo.opciones }>
            { 
                btnPredet 
                ? <img className={ PautaDetalleEstilo.iconoOpcPrensa } onClick={ () => cambiarTipoVisorPrensa(1) } src={ IconoVisorGralSelected } />  
                : <img className={ PautaDetalleEstilo.iconoOpcPrensaSet } style={{ cursor: 'pointer' }} onClick={ () => cambiarTipoVisorPrensa(1) } src={ IconoVisorGral } /> 
            } 
            {/* { 
                btnSeccion 
                ? <img className={ PautaDetalleEstilo.iconoOpcPrensa } onClick={ () => cambiarTipoVisorPrensa(2) } src={ IconoVisorSeccionSelected } /> 
                : <img className={ PautaDetalleEstilo.iconoOpcPrensaSet } style={{ cursor: 'pointer' }} onClick={ () => cambiarTipoVisorPrensa(2) } src={ IconoVisorImg } /> 
            } */}
            { 
                btnTxt 
                ? <img className={ PautaDetalleEstilo.iconoOpcPrensa } onClick={ () => cambiarTipoVisorPrensa(3) } src={ IconoVisorTxtSelected } /> 
                : <img className={ PautaDetalleEstilo.iconoOpcPrensaSet } style={{ cursor: 'pointer' }} onClick={ () => cambiarTipoVisorPrensa(3) } src={ IconoVisorTxt } /> 
            }
            { 
                btnImg 
                ? <img className={ PautaDetalleEstilo.iconoOpcPrensa } onClick={ () => cambiarTipoVisorPrensa(4) } src={ IconoVisorImgSelected }/> 
                : <img className={ PautaDetalleEstilo.iconoOpcPrensaSet } style={{ cursor: 'pointer' }} onClick={ () => cambiarTipoVisorPrensa(4) } src={ IconoVisorImgT } /> 
            }
        </div>,
       btnPredet, btnSeccion, 
       btnTxt, btnImg
    ]
}
