import React from 'react';

function Alert(props) {

  switch (props.type) {
    case 'info': var containerColor = '#00D1F4';
      break;
    case 'success': var containerColor = '#3DCC62';
      break;
    case 'error': var containerColor = '#FB4430';
      break;
    default: var containerColor = '#00D1F4';

  }

  const container = {
    display: 'flex',
    padding: '20px',
    background: containerColor,
  }

  const messageContainer = {
    color: '#fff'
  }

  const buttonClose = {
    border: 'none',
    background: 'transparent',
    marginLeft: '20px',
    color: '#fff',
    fontSize: '20px'
  }

  return (

    <div style={container}>
    <div style={messageContainer}>{props.message}</div>
    <button style={buttonClose} onClick={props.close}>x</button>
    </div>

  )
}

export default Alert