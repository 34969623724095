import React from 'react'
import VisoresEstilos from '../Visores.module.css'


export const VisorTextoInternet = ({
    desResumen
}) => {
    return (
        <div className={ VisoresEstilos.containerNoticia }>
            <div className={ VisoresEstilos.cajaTexto }>
                { desResumen }
            </div>
        </div>
    )
}
