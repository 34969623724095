import React, { useEffect, useState } from 'react'
import { useParams, NavLink } from 'react-router-dom'

import ApiService from '../../services/ApiService';
import { OpcionesPrensa } from './OpcionesPrensa';
import { OpcionesInternet } from './OpcionesInternet';

import PautaDetalleEstilo from './PautaDetalle.module.css';
import { Predeterminado } from '../../views/visores/Prensa/Predeterminado';
import { Texto } from '../../views/visores/Prensa/Texto';
import { Imagen } from '../../views/visores/Prensa/Imagen';
import { ImagenTotal } from '../../views/visores/Prensa/ImagenTotal';

import LogoIcon from "../../assets/img/logo-icon.png"
import { VisorRadio } from '../../views/visores/Radio/VisorRadio';
import { VisorTv } from '../../views/visores/Tv/VisorTv';
import { VisorInternet } from '../../views/visores/Internet/VisorInternet';
import { VisorTextoInternet } from '../../views/visores/Internet/VisorTextoInternet';

export const PautaDetalle = () => {
    const { pautaId }       =  useParams();
    const { idTipoMedio }   =  useParams();

    const [ isLoading, setIsLoading ] = useState(true)
    const [ htmlOpciones, btnPredet, btnSeccion, btnTxt, btnImg ] = OpcionesPrensa()
    const [ htmlOpcionesInternet, btnPredetInt, btnTxtInt ] = OpcionesInternet()

    const [ 
        {
            impEquivalencia, fecPauta, 
            nomSeccion, numAlto, 
            numAncho, numPagina, nomMedio,
            desResumen, desTitular, nomProgramaAv,
            horPauta, des_ruta_web, nomRecortePrensa, 
            desRutaImagen, alcance, aRecorte, desPalabraClave
        }, 
        setPauta 
    ] = useState({
        impEquivalencia: '',
        fecPauta: '',
        nomSeccion: '',
        numAlto: '',
        numAncho: '',
        numPagina: '',
        nomMedio: '',
        desResumen: '',
        desTitular: '',
        nomProgramaAv: '',
        horPauta: '',
        des_ruta_web: '',
        alcance: '',
        nomRecortePrensa: '',
        desRutaImagen: '',
        aRecorte: '',
        desPalabraClave: ''
    })

    useEffect(() => {
        
        setIsLoading(true);

        ApiService.buscarPautaDetalle(pautaId, idTipoMedio).then(
            response => {
                // console.log(response[0])
                let pauta = response;
                    setPauta({
                        impEquivalencia: pauta?.imp_equivalencia,
                        fecPauta: pauta?.fec_pauta,
                        nomSeccion : pauta?.nom_seccion,
                        numAlto : pauta?.num_alto,
                        numAncho : pauta?.num_ancho,
                        numPagina : pauta?.num_pagina,
                        nomMedio : pauta?.nom_medio,
                        desResumen : pauta?.des_resumen,
                        desTitular : pauta?.des_titular,
                        nomProgramaAv : pauta?.nom_programa_av,
                        horPauta : pauta?.hor_pauta,
                        des_ruta_web : pauta?.des_ruta_web,
                        alcance : pauta?.alcance,
                        nomRecortePrensa : pauta?.nom_recorte_prensa,
                        desRutaImagen : pauta?.des_ruta_imagen,
                        aRecorte : pauta?.aRecorte,
                        desPalabraClave : pauta?.des_palabraclave,
                    })
                    
                    setIsLoading(false);

            }, error => { console.log('Error al buscar pautas') }
        )
    }, [])

    return (  
        <div className={ PautaDetalleEstilo.container }>
             <div className={PautaDetalleEstilo.containerBarMain}>
                <div className="headerLeft">
                    <nav role="navigation">
                        <div id="menuToggle">
                            <input type="checkbox" />
                            
                            <span></span>
                            <span></span>
                            <span></span>
                            
                            <ul id="menu">
                            <div>
                                <img className="imgResponsiveMenu" src={LogoIcon} />
                            </div>
                            <hr className="separadorMenuResponsive" />
                            
                            <NavLink exact to="/buscar">
                                <p className="items">BUSCADOR</p>
                            </NavLink>
                            <NavLink exact to="/login">
                                <p className="items">CERRAR SESIÓN</p>
                            </NavLink>
                            </ul>
                        </div>
                    </nav>
                    <img className="imgCabeceraPrincipal" src={LogoIcon} />
                    <NavLink 
                        className="barraHeaderMain"
                        activeClassName="active"
                        exact
                        to="/buscar"
                    >
                        <p className="items">BUSCADOR</p>
                    </NavLink>
                </div>
            </div>
            <div className={ idTipoMedio == 1 ? PautaDetalleEstilo.header : PautaDetalleEstilo.headerGenerico }>
            {/* <div className={ PautaDetalleEstilo.header }> */}
                <div className={ PautaDetalleEstilo.bloqueLogo }>
                    <img src={ LogoIcon } className={ PautaDetalleEstilo.logo } />
                </div>
                { idTipoMedio == 1 && htmlOpciones }
                { idTipoMedio == 4 && htmlOpcionesInternet }
                <div style={{ display: 'flex', flex: (idTipoMedio === 4) ? 6 : 2}}></div>
            </div>
            <div>
                <div className={ PautaDetalleEstilo.containerBody}>
                    {
                        (idTipoMedio == 1) && 
                        <Predeterminado
                            isLoading = { isLoading }
                            fecPauta = { fecPauta } 
                            nomMedio = { nomMedio } 
                            nomSeccion = { nomSeccion } 
                            numAlto = { numAlto } 
                            numAncho = { numAncho } 
                            numPagina = { numPagina } 
                            impEquivalencia = { impEquivalencia } 
                            nomRecortePrensa = { nomRecortePrensa }
                            desResumen = { desResumen }
                            indMostrarPredet = { btnPredet }
                            indMostrarSeccion = { btnSeccion }
                            indMostrarTxt = { btnTxt }
                            indMostrarImg = { btnImg }
                            aRecorte = { aRecorte }
                        /> 
                    }
                    {
                        (idTipoMedio == 2) && 
                        <VisorTv 
                            isLoading = { isLoading }
                            desTitular= { desTitular }
                            desResumen= { desResumen }
                            fecPauta = { fecPauta } 
                            nomMedio = { nomMedio } 
                            impEquivalencia = { impEquivalencia }
                            nomProgramaAv = { nomProgramaAv }
                            horPauta = { horPauta }
                            pautaId = { pautaId }
                        />
                    }
                    {
                        (idTipoMedio == 3) && 
                        <VisorRadio 
                            isLoading = { isLoading }
                            desTitular = { desTitular }
                            desResumen = { desResumen }
                            fecPauta = { fecPauta } 
                            nomMedio = { nomMedio } 
                            impEquivalencia = { impEquivalencia }
                            nomProgramaAv = { nomProgramaAv }
                            horPauta = { horPauta }
                            pautaId = { pautaId }
                            />
                    }
                    {
                        
                        (btnPredetInt && idTipoMedio == 4) && 
                        <VisorInternet 
                            isLoading = { isLoading }
                            desTitular = { desTitular }
                            fecPauta = { fecPauta } 
                            nomMedio = { nomMedio } 
                            impEquivalencia = { impEquivalencia }
                            des_ruta_web = { des_ruta_web }
                            alcance = { alcance }
                            desRutaImagen = { desRutaImagen }
                            desPalabraClave = { desPalabraClave }
                        />
                    }
                    {
                        (btnTxtInt && idTipoMedio == 4) && 
                        <VisorTextoInternet 
                            desResumen= { desResumen }
                        />
                    }    
                </div>
            </div>
        </div>
    )
}